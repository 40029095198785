/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:index?",
    name: "Home",
    component: () => import("@/views/home/Home.vue"),
  },
  // {
  //   path: "/nft/:index?",
  //   name: "Nft",
  //   component: () => import("@/views/landing/Nft.vue"),
  // },
  {
    path: "/nft-detail/:addr?/:tokenId?",
    name: "NftDetail",
    component: () => import("@/views/landing/NftDetail.vue"),
  },
  {
    path: "/items-detail/:txHash/:accountAddr",
    name: "ItemsDetail",
    component: () => import("@/views/landing/ItemsDetail.vue"),
  },
  {
    path: "/item-detail",
    name: "ItemDetail",
    component: () => import("@/views/landing/ItemDetail.vue"),
  },
  {
    path: "/collection-detail/:contract",
    name: "CollectionDetail",
    component: () => import("@/views/landing/CollectionDetail.vue"),
  },
  {
    path: "/wallet-detail/:addr?",
    name: "WalletDetail",
    component: () => import("@/views/landing/WalletDetail.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "notFound",
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
