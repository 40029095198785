/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import error from "@/assets/feed/defaultImage.webp";
export default {
  install(app: any): void {
    const observe = (el, binding) => {
      const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isIntersecting) {
          observer.unobserve(el)
          el.src = binding.value;
          el.onerror = () => {
            el.src = error;
          }
        }
      }, {
        threshold: 0
      })
      observer.observe(el)
    };
    app.directive('lazyload', {
      beforeUpdate(el, binding) {
        observe(el, binding);
      },
      mounted(el, binding) {
        el.src = error;
        observe(el, binding);
      },
    })
  }
}
