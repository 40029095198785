/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { Guid } from "guid-typescript";
import { getQueriesFromUrl } from "@/utils/url";
const firstGetUid = () => {
  const { news_device_id = "" } = getQueriesFromUrl(location.href);
  const uid = news_device_id || localStorage.getItem("uid") || Guid.create().toString();
  localStorage.setItem("uid", uid);
  return uid;
}
export {
  firstGetUid
}