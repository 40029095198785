/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { createStore } from "vuex";
import { i18n, lang } from "@/locales";
import { getQueriesFromUrl } from "@/utils/url";
import { firstGetUid } from "@/utils/firstGetUid";
const { country = "us" } = getQueriesFromUrl(location.href);
const uid = firstGetUid();
localStorage.setItem("uid", uid);

export interface State {
  lang: string; // language
  uid: string;
  appVersion: string;
  country: string;
}

export default createStore<State>({
  state() {
    return {
      lang,
      uid,
      appVersion: "0.1.0",
      country: country.toLowerCase(),
    };
  },
  mutations: {
    changeLang(state: State, lang: string) {
      state.lang = lang;
      i18n.global.locale = lang as any;
      localStorage.lang = lang;
    },
  },
  actions: {
    changeLang({ commit }, lang: string) {
      commit("changeLang", lang);
    },
  },
  getters: {
    uid({ uid }) {
      return uid;
    },
    lang({ lang }) {
      return lang;
    },
    country({ country }) {
      return country;
    },
    appVersion(state) {
      return state.appVersion;
    },
  },
});
