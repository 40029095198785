export default {
  "Ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
  "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
  "Ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ended"])},
  "Floor Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor Price"])},
  "Last Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Price"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
  "Quality NFTs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality NFTs"])},
  "Top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
  "Collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
  "Ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
  "Recently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently "])},
  "Transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "Last 1 hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 1 hour"])},
  "Last 24 hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 24 hours"])},
  "Last 7 days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
  "Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "high to low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["high to low"])},
  "low to high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["low to high"])},
  "Recently sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently sold"])},
  "Current Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Price"])},
  "Meta data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta data"])},
  "Contract address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract address"])},
  "Token ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token ID"])},
  "Token standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token standard"])},
  "Blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain"])},
  "Item activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item activity"])},
  "View Collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Collection"])},
  "Buy on Opensea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy on Opensea"])},
  "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
  "Owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owners"])},
  "below the average price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["below the average price"])},
  "above the average price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["above the average price"])},
  "seconds ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds ago"])},
  "minute ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute ago"])},
  "minutes ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes ago"])},
  "hour ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour ago"])},
  "hours ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours ago"])},
  "day ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day ago"])},
  "days ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days ago"])},
  "month ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month ago"])},
  "months ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months ago"])},
  "Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
  "ALL NFTs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL NFTs"])},
  "Social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
  "PFP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PFP"])},
  "Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
  "Collectibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectibles"])},
  "Utility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utility"])},
  "Sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports"])},
  "Photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photography"])},
  "DeFi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DeFi"])},
  "Land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "Music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
  "Art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
  "Metaverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metaverse"])},
  "IP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "Domain Names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Names"])},
  "Last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%Change"])},
  "Learn More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
  "Find Out More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Out More"])},
  "Whales Tracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whales Tracker"])},
  "Tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
  "Rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
  "NextBlueChip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NextBlueChip"])},
  "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
  "BlueChip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BlueChip"])},
  "Flip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flip"])},
  "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
  "Profits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profits"])},
  "Flip Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flip Rate"])},
  "NFTs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs"])},
  "Total Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Value"])},
  "Total Cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost"])},
  "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "Hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "Following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following"])},
  "Receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive"])},
  "Cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
  "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ago"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "ETH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
  "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
  "Floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
  "Whale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whale"])},
  "View more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less"])},
  "Copy successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy successfully"])},
  "Free mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free mint"])}
}