/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { getQueriesFromUrl } from "@/utils/url";
import { createI18n } from "vue-i18n";
import en from "./languages/en.json";
// const langObj = { en: "us", ru: "ru", fr: "fr", de: "de", pt: "br"};
const langArray = ["en"];
let { lang = "en" } = getQueriesFromUrl(location.href);
lang = lang.toLowerCase();
if (langArray.indexOf(lang) === -1) {
  lang = "en";
}

const i18n = createI18n({
  legacy: process.env.NODE_ENV === 'development' ? false : true,
  locale: lang,
  globalInjection: true,
  messages: {
    en,
  },
});

// export default i18n
export { i18n, langArray, lang };
